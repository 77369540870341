/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import kebabCase from "lodash/kebabCase"
import get from "lodash/get"
import { Flex } from "@theme-ui/components"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import { renderLineBreak, getBackgroundList } from "../helpers"

import CTA from "./cta"
import Container from "./container"
import Column from "./column"
import Row from "./row"
import Text from "./text"

const ProjectUnique = ({ data, titlePosition }) => {
  const textStyle = {
    color: theme => theme.colors.white,
  }
  //TODO: refactor this asap
  const flexStyle = {
    top: { flexDirection: ["column"] },
    bottom: { flexDirection: ["column", "column", "column-reverse"] },
  }
  const rowStyle = {
    top: { mt: theme => [0, 0, theme.spacing.vertical.lg] },
    bottom: { mt: [0, 0, 8] },
  }
   const id  =  data?.anchorLink ||"test-id";
  return (
    <div
      id={id}
      sx={{
        background: getBackgroundList(data),
        backgroundPosition: "center !important",
        backgroundSize: "cover !important",
        position: "relative",
        py: theme => theme.spacing.vertical.lg,
        "::before": {
          backgroundColor: "rgba(0,0,0, 0.8)",
          content: '""',
          display: "block",
          height: "100%",
          position: "absolute",
          width: "100%",
          top: 0,
          left: 0,
        },
      }}
    >
      <Container>
        <Flex sx={flexStyle[titlePosition]}>
          {data?.title && (<Row
            customStyle={{
              mt: theme => [0, 0, theme.spacing.vertical.lg],
              zIndex: 2,
            }}
          >
            <Flex>
              <Text
                type="h1"
                customStyle={{ fontSize: [36, 36, 56], ...textStyle }}
              >
                {renderLineBreak(data.title)}
              </Text>
            </Flex>
          </Row>)}
          <Row
            customStyle={{
              ...rowStyle[titlePosition],
              zIndex: 2,
            }}
          >
            {data.contentCards.map(contentCard => (
              <Column
                customStyle={{
                  mt: theme => [theme.spacing.vertical.md],
                  pr: theme => [theme.spacing.vertical.md],
                }}
                size={[12, 12, 3]}
                key={kebabCase(contentCard.title)}
              >
                <Flex sx={{ alignItems: "center" }}>
                  {contentCard.desktopImage ? (
                    <img
                      sx={{
                        mr: 3,
                        justifyItems: "center",
                        mb: 0,
                        width: 40,
                      }}
                      alt=""
                      src={contentCard.desktopImage.file.url}
                    />
                  ) : null}

                  <Text type="h3" customStyle={textStyle}>
                    {contentCard.title}
                  </Text>
                </Flex>

                {contentCard.description &&
                  documentToReactComponents(contentCard.description.json, {
                    renderNode: {
                      [BLOCKS.PARAGRAPH]: (node, children) => (
                        <Text
                          customStyle={{
                            fontSize: 17,
                            fontWeight: "450",
                            mt: theme => [theme.spacing.vertical.sm],
                            textShadow: "1px 1px 7px rgba(0,0,0,0.6)",
                            ...textStyle,
                          }}
                        >
                          {children}
                        </Text>
                      ),
                    },
                  })}

                <div
                  sx={{
                    mt: theme => [
                      theme.spacing.vertical.sm,
                      theme.spacing.vertical.md,
                    ],
                  }}
                >
                  <CTA
                    link={get(
                      contentCard,
                      "ctaDestination.ctaDestination",
                      "/"
                    )}
                    type="secondary"
                    linkType="internal"
                  >
                    {contentCard.ctaTitle}
                  </CTA>
                </div>
              </Column>
            ))}
          </Row>
        </Flex>
      </Container>
    </div>
  )
}
ProjectUnique.defaultProps = {
  titlePosition: "top",
}
ProjectUnique.propTypes = {
  data: PropTypes.object.isRequired,
  titlePosition: PropTypes.oneOf(["top", "bottom"]),
}

export default ProjectUnique
